// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

#mapid { height: 700px; }

.pointer{
  cursor: pointer;
}

.navbar-brand-vista{
  width: 130px !important;
}

.scrollable-menu {
    max-height: 250px;
    overflow-x: hidden;
}

.collapseAnswer {
  display: block;
  height: 0px !important;
  overflow: hidden;
}

.collapse {
  display: none;
}

.reg-text {
  color: #0C0B17;
  font-family: Montserrat,sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
}

.sm-text {
  color: #0C0B17;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 100;
  line-height: 25px;
  text-align: left;
}

.title {
  color: #0C0B17;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
}

.reg-bold {
  font-weight: 700;
}

.big-number {
  font-family: Roboto, sans-serif;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  font-weight: bold;
  margin-bottom: -10px;
}

.reg-number {
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: -10px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bg-positive {
  background-color: #7FBC41;
}

.bg-negative {
  background-color: #DE77AE;
}

.bg-neutral {
  background-color: #20A8D8;
}

.txt-blue {
  color: #20A8D8;
}

.text-form-danger {
  margin-top: -15px;
  color: red;
  font-size: 10px;
}

.row-profile-set {
  margin-inline-end: 15px;
  margin-inline-start: 15px;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.form-button {
  margin-left: 15px;
  margin-right: 15px;
  margin-inline: 0px;
  -webkit-margin-start: 15px;
  -webkit-margin-end: 15px;
}

.custom-modal > .modal-title {
  width: 100%;
}
