// Variable overrides
.sidebar-lg-show.sidebar-fixed.sidebar-minimized .main, .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer, .sidebar-show.sidebar-fixed.sidebar-minimized .main, .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
  margin-left: 50px!important;
}

.sidebar-lg-show.sidebar-fixed .main, .sidebar-lg-show.sidebar-fixed .app-footer, .sidebar-show.sidebar-fixed .main, .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 310px!important;
}

.app-body .sidebar {
  flex: 0 0 310px!important;
}

.sidebar-minimized.sidebar-fixed .sidebar {
    width: 50px!important;
}
.sidebar-minimized .sidebar .sidebar-nav {
    overflow: visible;
    width: 50px!important;
}


.sidebar .nav {
  width: 310px!important;
}
.sidebar .sidebar-nav {
  width: 310px!important;
}
.sidebar {
  width: 310px!important;
}

.app-body .sidebar {
  flex: 0 0 310px!important;
  }

.sidebar {
  width: 310px;
}
.sidebar .nav {
  width: 310px!important;
}
.sidebar .sidebar-nav {
  width: 310px!important;
}